import { useContext, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { 
  fetchConversationWithMessages, 
  selectAllConversations, 
  removeConversationById
} from 'src/features/account/conversations.slice';
import { 
  getContact,
  removeContactState,
  selectAllContacts, 
  updateContactState 
} from 'src/features/account/contacts.slice';
import { 
  fetchChannel, 
  processPusherChannelUpdated, 
  processPusherChannelCreated, 
  processPusherChannelDeleted 
} from 'src/features/account/channels.slice';
import useAuth from 'src/hooks/useAuth';
import { PusherContext } from 'src/services/auth.service'; 
import LiveTranscriptionDialog from '../Lottie/LiveTranscriptionDialog';
import { getMessage } from 'src/features/account/messages.slice';

const AccountNotifications = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pusher = useContext(PusherContext);
  const [pusherSubscriptionSucceeded, setPusherSubscriptionSucceeded] = useState(false);
  const contacts = useAppSelector(selectAllContacts);
  // const handlePusherEvent = useHandlePusherEvents();
  const [transcriptionDialogOpen, setTranscriptionDialogOpen] = useState(false);
  const [latestTranscriptionChunk, setLatestTranscriptionChunk] = useState(null);
  const [transcriptionMessages, setTranscriptionMessages] = useState([]);

  useEffect(() => {
    if (isLoggedIn && user && !pusherSubscriptionSucceeded) {
      pusher.signin();
      setPusherSubscriptionSucceeded(true);
      pusher.bind('pusher:subscription_succeeded', () => {
        console.log('pusher:subscription_succeeded! setting state');
        setPusherSubscriptionSucceeded(true);
        enqueueSnackbar('Live connection succeeded!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
      });
    }
    if (isLoggedIn && user && pusherSubscriptionSucceeded) {
      const channel = pusher.subscribe('presence-account-' + user.account.uuid);

      const bindEvents = () => {
        ['channel_created', 'channel_updated', 'channel_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log(`account_notifications ${eventName} - pusher_data:`, data);
            if (eventName === 'channel_created' || eventName === 'channel_updated') {
              const channelId = data.channel.id as string; // Ensure channelId is a string
              dispatch(fetchChannel(channelId));
            }
          });
        });

        ['conversation_created', 'conversation_updated', 'conversation_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            if (eventName === 'conversation_created' || eventName === 'conversation_updated') {
              console.log(`Fetching updated conversation for ${eventName}`);
              dispatch(fetchConversationWithMessages({ conversationId: data.conversation.id, messagesLimit: 0 }));
            }
            if (eventName === 'conversation_deleted') {
              console.log(`Deleting conversation for ${eventName}`);
              dispatch(removeConversationById(data.conversation.id));
            }
          });
        });
        
        ['message_created', 'message_updated', 'message_deleted'].forEach(eventName => {
          console.log('eventName', eventName);
          channel.bind(eventName, (data) => {
            if (eventName === 'message_created' || eventName === 'message_updated') {
              console.log(`Fetching updated message for ${eventName}`);
              dispatch(getMessage({ messageId: data.message.id }));
            }
            if (eventName === 'message_deleted') {
              console.log(`Deleting message for ${eventName}`);
              // dispatch(removeMessageFromConversation(data.message.id));
            }
          });
        });
        ['transcript_updated'].forEach(eventName => {
          console.log('eventName', eventName);
          channel.bind(eventName, (data) => {
            console.log('transcript_updated - pusher_data:', data);
            setLatestTranscriptionChunk(data.transcription);
            setTranscriptionMessages(prev => {
              if (prev.length > 0) {
                const lastMessage = prev[prev.length - 1];
                return [
                  ...prev.slice(0, -1),
                  { ...lastMessage, text: lastMessage.text + ' ' + data.transcription }
                ];
              } else {
                return [{ speaker: 'System', text: data.transcription }];
              }
            });
            if (!transcriptionDialogOpen) {
              setTranscriptionDialogOpen(true);
            }
          });
        });
        ['contact_created', 'contact_updated', 'contact_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('contact_created - pusher_data:', data);
            console.log(`${eventName} - pusher_data:`, data);
            if (data.contact && data.contact.id) {
              if (eventName === 'contact_created' || eventName === 'contact_updated') {
                dispatch(getContact(data.contact.id));
              } else if (eventName === 'contact_deleted') {
                console.log(`Removing contact for ${eventName}`);
                dispatch(removeContactState(data.contact.id));
              }
            }
          });
        });
        ['entity_created', 'entity_updated', 'entity_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('entity_created - pusher_data:', data);
            // setLatestChannelPusherEvent({ eventName, data });
          });
        });
        ['faq_created', 'faq_updated', 'faq_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('faq updated - pusher_data:', data);
            // setLatestChannelPusherEvent({ eventName, data });
          });
        });
      };

      bindEvents();

      return () => {
        channel.unbind_all(); // Clean up: unbind all events when the component unmounts or data changes
        channel.unsubscribe();
      };
    }
  }, [isLoggedIn, user, pusherSubscriptionSucceeded, authLoading, dispatch]);

  return (
    <>
      <LiveTranscriptionDialog
        open={transcriptionDialogOpen}
        onClose={() => setTranscriptionDialogOpen(false)}
        messages={transcriptionMessages}
      />
    </>
  );
}

export default AccountNotifications;
