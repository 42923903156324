import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,   
  useTheme,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { Mail, Phone, CallMade, Settings, Edit, AdminPanelSettings, Web } from '@mui/icons-material';
import AssistantIcon from '../ThemeElements/AssistantIcon';
import LavaLampBackground from '../ThemeElements/LavaLampBackground';
import ChannelDescriptionTextInput from './ChannelDescriptionTextInput';
import ConversationCreateDialogV2 from './ConversationCreateDialogV2';
import OutboundOptionsMenu from './OutboundOptionsMenu';
import ConversationCallOptions from './ConversationCallOptions';
import QrCodeDialog from './QrCodeDialog';
import ChannelEditDialog from './ChannelEditDialog';
import UpdateAdminSettings from './UpdateAdminSettings';
import EmbedKeyInput from './EmbedKeyInput';
import PhoneNumberBrowserDialog from './PhoneNumberBrowserDialog';
import { useAppSelector, useAppDispatch } from 'src/store';
import ReleasePhoneNumber from './ReleasePhoneNumber';
import parsePhoneNumberFromString from 'libphonenumber-js';
import ChannelEmbedDialog from './ChannelEmbedDialog';
import Code from '@mui/icons-material/Code';
import ChannelOptionsMenu from './ChannelOptionsMenu';
import { updateChannelDelivery, selectChannelById } from 'src/features/account/channels.slice';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: theme.spacing(4),
  minHeight: '400px',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '1200px',
  margin: '0',
}));

const AvatarWrapper = styled(Box)({
  position: 'relative',
  width: 125,
  height: 125,
  marginRight: 3,
});

const ChannelHeader = ({ channelId }) => {
  const channel = useAppSelector(state => selectChannelById(state, channelId));
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { allChannelReplies, leads, bookings } = useAppSelector(state => state.channels);
  const channelLoading = useAppSelector(state => state.channels.channelsLoading);
  const [showOutboundIcon, setShowOutboundIcon] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [conversationCallOptionsOpen, setConversationCallOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [phoneEditorOpen, setPhoneEditorOpen] = useState(false);
  const [embedDialogOpen, setEmbedDialogOpen] = useState(false);

  const handleIconClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({ top: rect.bottom, left: rect.left });
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleAdminMenuOpenToggle = () => {
    setAdminMenuOpen(!adminMenuOpen);
    handleSettingsClose();
  };

  const handleChannelDeliveryStateChange = (deliveryState) => {
    dispatch(updateChannelDelivery({ channelId: channel.id, data: { delivery_state: deliveryState } }))
  }

  const handleChannelDeliveryDelayChange = (delay) => {
    dispatch(updateChannelDelivery({ channelId: channel.id, data: { delivery_delay: delay } }))
  }

  const formatPhoneNumber = (phone_number) => {
    const phoneNumber = parsePhoneNumberFromString(phone_number);
    if (!phoneNumber) {
      console.log("Invalid phone number");
      return null;
    }
    return phoneNumber.formatInternational();
  };

  if (!channel) {
    return <Box>Loading...</Box>;
  }

  return (
    <StyledBox>
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1000 }}>
        <LavaLampBackground />
      </Box>
      
      <ContentBox>
        <Box display="flex" alignItems="center" mt={2} ml={-2} >
          <AvatarWrapper
            onMouseEnter={() => setShowOutboundIcon(true)}
            onMouseLeave={() => setShowOutboundIcon(false)}
          >
            <AssistantIcon size={125} movementSpeed={0.05} />
            {showOutboundIcon && (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  transition: '0.3s ease-in-out',
                  opacity: 0,
                  '&:hover': { 
                    opacity: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
                onClick={handleIconClick}
              >
                <CallMade sx={{ color: 'white' }} />
              </IconButton>
            )}
            
          </AvatarWrapper>
          <Box>
            <Typography variant="h4" fontWeight="bold" mb={1}>{channel.chatbot_name}</Typography>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex" alignItems="center">
                <Mail sx={{ mr: 1, fontSize: 16 }} />
                <Typography variant="body2">{channel.channel_email_address}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Phone sx={{ mr: 1, fontSize: 16 }} />
                <Typography variant="body2">
                  {channel.twilio_phone_number ? 
                    formatPhoneNumber(channel.twilio_phone_number) : 
                    <Typography 
                      variant="body2" 
                      color="secondary" 
                      component="span"
                      sx={{ color: 'white', cursor: 'pointer' }}
                      onClick={() => setPhoneEditorOpen(true)}
                    >
                      Get Phone Number
                    </Typography>
                  }
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Web sx={{ mr: 1, fontSize: 16 }} />
                <EmbedKeyInput style={{ fontSize: 16, color: 'white' }} channelId={channel.id} initialEmbedKey={channel.embed_key || ''} />
              </Box>
            </Box>
          </Box>
        </Box>
        
        <Box mt={4} mb={4}>
          <Typography variant="h6" fontWeight="bold" mb={1} color="white">Description</Typography>
          <ChannelDescriptionTextInput channel={channel} />
        </Box>

        <Box display="flex" gap={4} alignItems="flex-start" mt={4} sx={{ maxWidth: '100vw', height: 'auto', overflowY: 'scroll' }}>
          <Box sx={{
            height: '75px',
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            flexWrap: 'nowrap',
            minWidth: '700px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}>
            {/* <Button 
              startIcon={<Phone />} 
              variant="contained" 
              color="secondary" 
              onClick={() => channel.twilio_phone_number ? setConversationCallOptionsOpen(true) : setPhoneEditorOpen(true)}
            >
              {channel.twilio_phone_number ? 'Place Call' : 'Purchase Phone Number'}
            </Button> */}
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start" >
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  href={`${window.location.origin}/s/${channel.embed_key}?s=${channel.style}`}
                  target="_blank"
                >
                  Open Landing Page
                </Button>
                <QrCodeDialog
                  defaultPhoneNumber={channel.twilio_phone_number}
                  defaultEmail={channel.channel_email_address}
                  defaultBaseUrl={`${window.location.origin}/s/${channel.embed_key}`}
                  defaultUrl={`${window.location.origin}/s/${channel.embed_key}?s=${channel.style}`}
                  defaultInitialMessage={`Hey ${channel.chatbot_name}, I'd love to learn more about ${channel.config?.company_name}. My name is `}
                />
              </Box>
              <Typography variant="caption" color="white" sx={{ display: 'block', mt: 1 }}>
                <EmbedKeyInput
                 textFieldStyle={{ fontSize: 16, color: 'white' }} 
                 parentWrapperStyle={{ maxWidth: '300px', wordBreak: 'break-word' }} 
                 linkWrapperStyle={{mt:-2, display:'flex', flexDirection:'row', alignItems:'center'}} 
                 channelId={channel.id} 
                 initialEmbedKey={channel.embed_key || ''} 
                />
              </Typography>
            </Box>
            <Button
              startIcon={<Edit />}
              variant="contained"
              color="secondary"
              onClick={() => setEditorOpen(true)}
            >
              Edit this Oppy
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Code />}
              onClick={() => setEmbedDialogOpen(true)}
            >
              Embed
            </Button>
          </Box>
        </Box>
      
        
        {/* <Box
          mt={4}
          mb={-8}
          sx={{
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            opacity: 0.5,
            transition: 'opacity 0.3s',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Typography variant="caption" color="white">
            Messages: {allChannelReplies} | Leads: {leads} | Bookings: {bookings}
          </Typography>
          {channel.twilio_phone_number && (
            <ReleasePhoneNumber
              channelId={channel.id}
              formatPhoneNumber={formatPhoneNumber}
            />
          )}
        </Box>
         */}
        {conversationCallOptionsOpen && 
          <ConversationCallOptions 
            open={conversationCallOptionsOpen} 
            channelId={channel.id}
            onClose={() => setConversationCallOptionsOpen(false)}
          />
        }
        {phoneEditorOpen && (
          <PhoneNumberBrowserDialog 
            openDialog={phoneEditorOpen} 
            handleCloseDialog={() => setPhoneEditorOpen(false)} 
            channelId={channel.id} 
          />
        )}
      </ContentBox>

      <OutboundOptionsMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onCreateDialogOpen={() => {
          setCreateDialogOpen(true);
          setAnchorEl(null);
        }}
        onPlaceCallDialogOpen={() => {
          setConversationCallOptionsOpen(true);
          setAnchorEl(null);
        }}
        position={menuPosition}
      />

      <ConversationCreateDialogV2
        open={createDialogOpen}
        handleClose={() => setCreateDialogOpen(false)}
        incomingChannelId={channel.id}
      />

      <IconButton
        sx={{
          position: 'absolute',
          top: theme.spacing(2),
          right: theme.spacing(2),
          color: 'white',
        }}
        onClick={handleSettingsClick}
      >
        <Settings />
      </IconButton>

      <Menu
        anchorEl={settingsAnchorEl}
        open={Boolean(settingsAnchorEl)}
        onClose={handleSettingsClose}
      >
        {/* delivery settings option that triggers ChannelOptionsMenu */}
        <MenuItem>
          <ChannelOptionsMenu channel={channel} handleChannelDeliveryStateChange={handleChannelDeliveryStateChange} handleChannelDeliveryDelayChange={handleChannelDeliveryDelayChange} loading={channelLoading} />
        </MenuItem>
        <MenuItem onClick={() => { setEditorOpen(true); handleSettingsClose(); }}>
          <Edit sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleAdminMenuOpenToggle}>
          <AdminPanelSettings sx={{ mr: 1 }} /> Admin Settings
        </MenuItem>
      </Menu>

      {editorOpen && <ChannelEditDialog open={editorOpen} channelId={channel.id} handleClose={() => setEditorOpen(false)} handleBack={() => setEditorOpen(false)} />}
      {adminMenuOpen && <UpdateAdminSettings styles={{
        fontSize: 16,
        maxWidth: "500px",
        mt: 2
      }} channel={channel} open={adminMenuOpen} setOpen={handleAdminMenuOpenToggle} />}
      <ChannelEmbedDialog
        open={embedDialogOpen}
        onClose={() => setEmbedDialogOpen(false)}
        channelId={channel.id}
      />
    </StyledBox>
  );
};

export default ChannelHeader;
