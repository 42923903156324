import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssistantIcon from '../ThemeElements/AssistantIcon';

const LiveTranscriptionComponent = ({ messages }) => {
  const [callState, setCallState] = useState('ringing');
  const [callDuration, setCallDuration] = useState(0);
  const [transcriptionComplete, setTranscriptionComplete] = useState(false);
  const [transcriptVisible, setTranscriptVisible] = useState(true);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setCallState('transcribing'), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval;
    if (callState === 'transcribing') {
      interval = setInterval(() => {
        setCallDuration(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [callState]);

  useEffect(() => {
    if (transcriptionComplete) {
      setExpanded(false);
    }
  }, [transcriptionComplete]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ width: '100%', maxWidth: 'lg' }}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AssistantIcon />
            {transcriptionComplete ? (
              <Button onClick={() => setTranscriptVisible(!transcriptVisible)}>
                Transcript Complete
              </Button>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        animation: 'fadeText 3s ease-in-out infinite',
                        '@keyframes fadeText': {
                          '0%': { opacity: .3 },
                          '50%': { opacity: .5 },
                          '100%': { opacity: .3 },
                        },
                      }}
                    >
                      Transcribing call...
                    </Typography>
                  </Box>
                </Box>          
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!transcriptionComplete && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeIcon sx={{ mr: 0.5 }} />
                <Typography variant="body2">{formatTime(callDuration)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3, height: '32rem', overflow: 'hidden' }}>
          {transcriptVisible && (
            <Paper sx={{ width: '100%', overflowY: 'auto', flexGrow: 1, p: 2 }}>
              {messages.map((item, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>{item.speaker}: </Typography>
                  <Typography component="span">{item.text}</Typography>
                </Box>
              ))}
            </Paper>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LiveTranscriptionComponent;
