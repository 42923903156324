import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts, setFilter } from 'src/features/account/filteredContacts.slice'; // Import setFilter
import { Button, Box, Grid, IconButton, TextField, CircularProgress, Popover, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import { selectIsFilterActive } from 'src/features/account/filteredContacts.slice'; // Import selector

const ContactsFilterBar = () => {
  const dispatch = useAppDispatch();
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const [inputValue, setInputValue] = useState('');
  // State for Popover anchor
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const initialFilters = {
    first_name: '',
    last_name: '',
    name: '',
    status: '',
    tags: [], // Keep as []
    company_name: '',
    address: '',
    created_at_before: '',
    created_at_after: '',
    last_contacted_at_before: '',
    last_contacted_at_after: '',
    zip: '',
    state: '',
    phone_number: '',
    email: '',
    without_phone_number: false,
    without_email: false,
  };

  const [advancedFilters, setAdvancedFilters] = useState(initialFilters);

  // Check if any filter param exists
  const isFilterActive = useAppSelector(selectIsFilterActive); // Use selector

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
    console.log('Input value changed:', newInputValue);
  };

  // Open Popover
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    console.log('Filter icon clicked, anchor set:', event.currentTarget);
  };

  // Close Popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
    console.log('Popover closed');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'advanced-filter-popover' : undefined;

  const handleAdvancedFilterChange = (field: string, value: any) => {
    if (field === 'tags') {
      // Split input string into array
      const tagsArray = value.split(',').map(tag => tag.trim());
      setAdvancedFilters(prev => ({ ...prev, tags: tagsArray }));
      console.log(`Advanced filter changed - ${field}:`, tagsArray); // Log the array
    } else {
      setAdvancedFilters(prev => ({ ...prev, [field]: value }));
      console.log(`Advanced filter changed - ${field}:`, value);
    }
  };

  // Function to format phone number to +1XXXXXXXXXX format
  const formatPhoneNumber = (phone: string): string => {
    console.log('Original phone number:', phone); // Log the original phone number
    // Remove all non-digit characters
    const digits = phone.replace(/\D/g, '');
    console.log('Digits extracted:', digits); // Show the digits extracted
    let formattedNumber = digits;
    if (digits.length === 10) {
      formattedNumber = '+1' + digits;
      console.log('Formatted phone number:', formattedNumber); // Show the formatted number
    } else if (digits.length === 11 && digits.startsWith('1')) {
      formattedNumber = '+' + digits;
      console.log('Formatted phone number:', formattedNumber); // Show the formatted number
    } else {
      console.log('Invalid phone number length'); // Log invalid length
      // Handle invalid phone number length
      formattedNumber = phone; // Keep as is or set to empty string if preferred
    }
    return formattedNumber;
  };

  // Updated handleSubmit to format phone number
  const handleSubmit = () => {
    const params = { query: inputValue, ...advancedFilters }; // Use 'const' instead of 'let'
    console.log('Submitting search with initial params:', params);

    // Format the phone number before submitting
    if (params.phone_number) {
      const formattedPhoneNumber = formatPhoneNumber(params.phone_number);
      params.phone_number = formattedPhoneNumber;
    }

    console.log('Submitting search with formatted params:', params);
    dispatch(setFilter(params)); // Update filterObject and isFilterActive
    dispatch(fetchFilteredContacts(params));
    handlePopoverClose();
  };

  // Function to clear all filters
  const handleClearFilters = () => {
    setAdvancedFilters(initialFilters);
    setInputValue('');
    console.log('All filters cleared');
    dispatch(setFilter({})); // Reset filters in Redux
  };

  // Function to clear individual date filters
  const handleClearDateFilter = (field: string) => {
    setAdvancedFilters(prev => ({ ...prev, [field]: '' }));
    console.log(`Date filter cleared - ${field}`);
  };

  // Function to handle key press events in input fields
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    console.log('Key pressed:', event.key); // Log the key pressed
    if (event.key === 'Enter') {
      console.log('Enter key detected. Triggering search.');
      handleSubmit();
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <TextField
          label="Search Contacts"
          placeholder="Search for contacts..."
          variant="standard"
          fullWidth
          autoComplete="off"
          value={inputValue}
          onChange={(event) => handleInputChange(event.target.value)}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <>
                {contactsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <IconButton onClick={handleFilterClick}>
                    <TuneIcon color={isFilterActive ? "primary" : "inherit"} />
                  </IconButton>
                )}
              </>
            ),
            inputProps: {
              sx: {
                // Disable LastPass popup
                '& div[data-lastpass-icon-root]': { display: 'none' },
                '& div[data-lastpass-root]': { display: 'none' },
              },
            },
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            {/* Existing advanced filter fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="standard"
                fullWidth
                value={advancedFilters.first_name}
                onChange={(e) => handleAdvancedFilterChange('first_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            {/* New advanced filter fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="standard"
                fullWidth
                value={advancedFilters.last_name}
                onChange={(e) => handleAdvancedFilterChange('last_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="standard"
                fullWidth
                value={advancedFilters.name}
                onChange={(e) => handleAdvancedFilterChange('name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Status"
                variant="standard"
                fullWidth
                value={advancedFilters.status}
                onChange={(e) => handleAdvancedFilterChange('status', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tags"
                variant="standard"
                fullWidth
                value={advancedFilters.tags.join(', ')} // Display array as comma-separated string
                onChange={(e) => handleAdvancedFilterChange('tags', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Name"
                variant="standard"
                fullWidth
                value={advancedFilters.company_name}
                onChange={(e) => handleAdvancedFilterChange('company_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                variant="standard"
                fullWidth
                value={advancedFilters.address}
                onChange={(e) => handleAdvancedFilterChange('address', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Zip"
                variant="standard"
                fullWidth
                value={advancedFilters.zip}
                onChange={(e) => handleAdvancedFilterChange('zip', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                variant="standard"
                fullWidth
                value={advancedFilters.state}
                onChange={(e) => handleAdvancedFilterChange('state', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="standard"
                fullWidth
                value={advancedFilters.phone_number}
                onChange={(e) => handleAdvancedFilterChange('phone_number', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                value={advancedFilters.email}
                onChange={(e) => handleAdvancedFilterChange('email', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Created At After"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.created_at_after}
                onChange={(e) => handleAdvancedFilterChange('created_at_after', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('created_at_after')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Created At Before"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.created_at_before}
                onChange={(e) => handleAdvancedFilterChange('created_at_before', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('created_at_before')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Contacted At After"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.last_contacted_at_after}
                onChange={(e) => handleAdvancedFilterChange('last_contacted_at_after', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('last_contacted_at_after')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Contacted At Before"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.last_contacted_at_before}
                onChange={(e) => handleAdvancedFilterChange('last_contacted_at_before', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('last_contacted_at_before')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={advancedFilters.without_phone_number}
                    onChange={(e) => handleAdvancedFilterChange('without_phone_number', e.target.checked)}
                  />
                }
                label="Without Phone Number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={advancedFilters.without_email}
                    onChange={(e) => handleAdvancedFilterChange('without_email', e.target.checked)}
                  />
                }
                label="Without Email"
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="secondary" onClick={handleClearFilters}>
              Clear Filters
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Search
            </Button>
            
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ContactsFilterBar;
