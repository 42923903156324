import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Autocomplete,
  Chip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateContact, selectContactById } from 'src/features/account/contacts.slice';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';

interface MultiEditDialogProps {
  open: boolean;
  onClose: () => void;
  selectedContactIds: string[];
}

const MultiEditDialog: React.FC<MultiEditDialogProps> = ({
  open,
  onClose,
  selectedContactIds,
}) => {
  const dispatch = useAppDispatch();
  const contacts = useAppSelector((state) =>
    selectedContactIds.map((id) => selectContactById(state, id))
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    status: '',
    tags: [] as string[], // Ensure 'tags' is initialized as an array
    // Add other fields as needed
  });
  const [placeholders, setPlaceholders] = useState({
    status: '',
    tags: [] as string[],
    // Add other fields as needed
  });
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (open) { // Only run effect when dialog is open
      console.log('Dialog opened with selectedContactIds:', selectedContactIds);
      
      // Compute common values among selected contacts
      const fields = ['status', 'tags']; // Extend this array with other fields as needed
      const newFormValues = { ...formValues };
      const newPlaceholders = { ...placeholders };

      fields.forEach((field) => {
        const fieldValues = contacts.map((contact) => {
          const value = contact[field];
          if (field === 'tags') {
            return Array.isArray(value) ? value : [];
          } else {
            return value !== undefined && value !== null ? value : '';
          }
        });

        if (field === 'tags') {
          const uniqueTags = Array.from(new Set(fieldValues.flat()));
          if (uniqueTags.length === 1) {
            newFormValues[field] = uniqueTags; // Ensure it's an array
            newPlaceholders[field] = [];
          } else {
            newFormValues[field] = []; // Empty array for multiple values
            newPlaceholders[field] = ['Multiple values'];
          }
        } else {
          const uniqueValues = Array.from(new Set(fieldValues));
          if (uniqueValues.length === 1) {
            newFormValues[field] = uniqueValues[0];
            newPlaceholders[field] = '';
          } else {
            newFormValues[field] = '';
            newPlaceholders[field] = 'Multiple values';
          }
        }
      });

      setFormValues(newFormValues);
      setPlaceholders(newPlaceholders);
      console.log('Updated formValues:', newFormValues);
      console.log('Updated placeholders:', newPlaceholders);
    }
  }, [open, selectedContactIds]); // Added 'open' to dependencies

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleTagChange = (event, newValue) => {
    console.log('Tags changed:', newValue);
    setFormValues({ ...formValues, tags: newValue });
  };

  // Add a function to handle key presses in the tags input
  const handleKeyDownTags = (event) => {
    if (event.key === ' ') {
      event.preventDefault(); // Prevent space from being entered in the input
      const trimmedValue = inputValue.trim();
      if (trimmedValue !== '') {
        const newTags = [...formValues.tags, trimmedValue];
        setFormValues({ ...formValues, tags: newTags });
        setInputValue(''); // Clear the input
        console.log('Added tag:', trimmedValue);
        console.log('Current tags:', newTags);
      }
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    const updates = {};

    // Include only fields that have values
    Object.keys(formValues).forEach((key) => {
      if (key === 'tags' && formValues.tags.length > 0) {
        // We'll handle tags separately
      } else if (formValues[key] !== '' && formValues[key] !== undefined && formValues[key] !== null) {
        updates[key] = formValues[key];
      }
    });

    console.log('Updates to be applied:', updates);

    Promise.all(
      selectedContactIds.map((contactId) => {
        const contact = contacts.find((c) => c.id === contactId);
        const existingTags = Array.isArray(contact.tags) ? contact.tags : [];
        const updatedTags = Array.from(new Set([...existingTags, ...formValues.tags]));
        console.log(`Updating contact ${contactId} with tags:`, updatedTags);
        return dispatch(updateContact({ id: contactId, tags: updatedTags, ...updates }));
      })
    )
      .then(() => {
        setIsSubmitting(false);
        onClose();
        dispatch(fetchFilteredContacts({ query: '' }));
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error('Failed to update contacts:', error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Multiple Contacts</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              name="status"
              label="Status"
              value={formValues.status}
              onChange={handleChange}
              fullWidth
              placeholder={placeholders.status || 'Leave blank to keep existing'}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={[]} // You can provide existing tags here
              value={formValues.tags}
              onChange={handleTagChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderTags={(value: string[], getTagProps) =>
                Array.isArray(value)
                  ? value.map((option: string, index: number) => (
                      <Chip
                        key={option}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Tags"
                  placeholder="Add tags"
                  onKeyDown={handleKeyDownTags} // Add key down handler
                />
              )}
            />
          </Grid>
          {/* Add other fields as needed */}
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 3 }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  Updating...
                </>
              ) : (
                'Update Contacts'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MultiEditDialog;
