import React, { useState, useEffect } from 'react';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography, Card, CircularProgress, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { createContact, updateContact, deleteContact } from 'src/features/account/contacts.slice';
import ContactsDataGridV2 from './ContactsDataGridV2';
import ContactsList from './ContactsList';
import ContactCreateDialog from './ContactCreateDialog';
import ContactsFilterBar from './ContactsFilterBar';
import ContactsUploaderDialog from './uploader/ContactsUploaderDialog';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import { isEmpty } from 'lodash';
import { ViewAgenda, ViewStream } from '@mui/icons-material'; // Updated imports
// Removed unused imports: ViewList, GridView
// Import Oppy image
import { getBranding } from 'src/utils/branding';
import { selectIsFilterActive } from 'src/features/account/filteredContacts.slice'; // Import selector
import { setFilter } from 'src/features/account/filteredContacts.slice'; // Import setFilter
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Import PersonAddIcon
import ImportContactsIcon from '@mui/icons-material/ImportContacts'; // Import ImportContactsIcon
import BulkConversationCreateDialog from '../Channels/BulkConversationCreateDialog';
import MultiEditDialog from './MultiEditDialog'; // Import MultiEditDialog

const ContactsWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const [viewMode, setViewMode] = useState<'list' | 'grid'>('list');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const isFilterActive = useAppSelector(selectIsFilterActive); // Use selector
  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [multiEditDialogOpen, setMultiEditDialogOpen] = useState(false); // State for MultiEditDialog

  // Get contacts and loading status from Redux store
  const contacts = useAppSelector(selectAllFilteredContacts);
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading || state.contacts.contactsLoading);
  const filterObject = useAppSelector(state => state.filteredContacts.filterObject);

  // Use 'oppy_expressions_drunk' to match 404.tsx
  const oppyImage = getBranding('oppy_expressions_drunk');

  useEffect(() => {
    if (isFilterActive) {
      console.log('Triggering search with filters:', filterObject);
      dispatch(fetchFilteredContacts(filterObject));
    } else {
      console.log('No query or filter parameters; not triggering search');
      // Optionally, fetch default contacts here if needed
      // dispatch(getContacts());
    }
  }, [dispatch, filterObject, isFilterActive]);

  const toggleViewIcon = viewMode === 'list' ? <ViewStream /> : <ViewAgenda />;

  const handleViewModeToggle = () => {
    setViewMode(prevMode => (prevMode === 'list' ? 'grid' : 'list'));
  };

  const handleCreateContact = (newContact) => {
    dispatch(createContact(newContact)).then(() => {
      dispatch(fetchFilteredContacts({}));
    });
  };

  const handleUpdateContact = (updatedContact) => {
    dispatch(updateContact(updatedContact)).then(() => {
      dispatch(fetchFilteredContacts(filterObject));
    });
  };

  const handleOpenContactsUploaderDialog = () => {
    dispatch(setOpenDialog('ContactsUploaderDialog'));
  };

  // Function to clear filters and fetch default contacts
  const handleClearFilters = () => {
    console.log('Clearing filters and fetching default contacts');
    dispatch(setFilter({})); // Reset filters in Redux
    dispatch(fetchFilteredContacts({}));
  };

  const handleContactsSelection = (selectedIds: string[]) => {
    console.log('Selected IDs:', selectedIds); // Log selected IDs
    console.log('All contacts:', contacts); // Log all contacts

    const selected = contacts.filter(contact => selectedIds.includes(contact.id.toString()));

    console.log('Filtered selected contacts:', selected); // Log filtered contacts

    setSelectedContacts(selected);
  };

  const handleBulkMessageClick = () => {
    setIsBulkDialogOpen(true);
  };

  const handleBulkDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${selectedContacts.length} selected contacts?`)) {
      Promise.all(selectedContacts.map(contact => dispatch(deleteContact(contact.id))))
        .then(() => {
          setSelectedContacts([]);
          dispatch(fetchFilteredContacts(filterObject));
        });
    }
  };

  // Function to handle bulk edit click
  const handleBulkEditClick = () => {
    setMultiEditDialogOpen(true);
    console.log('Bulk edit clicked');
  };

  // Define styles
  const styles = {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: 2,
      background: '#FAFAFA',
      width: '100%',
      mb: 2,
    },
    topBarAction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    noContactsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
    },
    oppyImage: {
      width: '200px',
      mb: 2,
    },
    clearButton: {
      mt: 2,
    },
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={styles.header}>
        <Typography variant="h4">Contacts</Typography>
        <Box sx={styles.topBarAction}>
          <IconButton onClick={handleViewModeToggle} sx={{ mr: 2 }}>
            {toggleViewIcon}
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => setIsCreateDialogOpen(true)}
            sx={{ mr: 2 }}
          >
            <PersonAddIcon /> {/* Replace Typography with PersonAddIcon */}
          </IconButton>
          <IconButton color="secondary" onClick={handleOpenContactsUploaderDialog}>
            <ImportContactsIcon /> {/* Replace Typography with ImportContactsIcon */}
          </IconButton>
        </Box>
      </Box>

      <ContactsFilterBar />
      
      {/* Show bulk action buttons when at least one contact is selected */}
      {selectedContacts && selectedContacts.length > 0 && (
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBulkEditClick}
          >
            Edit Selected ({selectedContacts.length})
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkMessageClick}
          >
            Send Bulk Message
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
          >
            Delete Selected
          </Button>
        </Box>
      )}
      {contactsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        !contactsLoading && contacts.length === 0 ? (
          <Box sx={styles.noContactsContainer}>
            <Card elevation={3} sx={{ p: 4, textAlign: 'center' }}>
              <Box
                component="img"
                src={oppyImage}
                alt="No contacts found"
                sx={styles.oppyImage}
              />
              <Typography variant="h5">
                {isEmpty(filterObject)
                  ? "No contacts here yet."
                  : "Couldn't find any contacts."}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {isEmpty(filterObject)
                  ? "Add your first contact to get started."
                  : "Try adjusting your search or clearing the filters."}
              </Typography>
              {isEmpty(filterObject) ? (
                <Button
                  variant="contained"
                  onClick={() => setIsCreateDialogOpen(true)}
                  sx={styles.clearButton}
                >
                  Add Contact
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleClearFilters}
                  sx={styles.clearButton}
                >
                  Clear Search
                </Button>
              )}
            </Card>
          </Box>
        ) : (
          viewMode === 'list' ? (
            <ContactsList onSelectionChange={handleContactsSelection} />
          ) : (
            <ContactsDataGridV2 onSelectionChange={handleContactsSelection} />
          )
        )
      )}

      <ContactCreateDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        handleContactUpdate={handleCreateContact}
      />

      <ContactsUploaderDialog />

      {/* MultiEditDialog for editing selected contacts */}
      {multiEditDialogOpen && (
        <MultiEditDialog
          open={multiEditDialogOpen}
          onClose={() => setMultiEditDialogOpen(false)}
          selectedContactIds={selectedContacts.map(contact => contact.id.toString())}
        />
      )}

      <BulkConversationCreateDialog
        open={isBulkDialogOpen}
        handleClose={() => setIsBulkDialogOpen(false)}
        selectedContacts={selectedContacts}
      />
    </Box>
  );
};

export default ContactsWrapper;
