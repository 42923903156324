import React from 'react';
import { Box, Typography } from '@mui/material';

interface BasicSlideTemplateProps {
  title?: string;
  imageUrl?: string;
  videoUrl?: string;
}

const BasicSlideTemplate: React.FC<BasicSlideTemplateProps> = ({ title, imageUrl, videoUrl }) => {
  console.log('Rendering BasicSlideTemplate with props:', { title, imageUrl, videoUrl });

  return (
    <Box
      sx={{
        minHeight: '70vh',          // Ensure minimum height of 70vh
        width: '100%',              // Full width
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',       // Center content horizontally
        justifyContent: 'center',   // Center content vertically
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',       // For absolute positioning of title
        overflow: 'hidden',
      }}
    >
      {/* Title aligned to top-left */}
      {title && (
        <Typography
          variant="h3"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            margin: '1rem',
            zIndex: 1,
          }}
        >
          {title}
        </Typography>
      )}

      {/* Content container */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',     // Center content vertically
          justifyContent: 'center', // Center content horizontally
        }}
      >
        {/* Image */}
        {imageUrl && (
          <Box
            component="img"
            src={imageUrl}
            alt="Slide Image"
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              display: 'block',
              minHeight: { xs: '70vh', sm: 'auto' }, // Larger image on xs screens
            }}
          />
        )}

        {/* Video */}
        {videoUrl && (
          <Box
            component="video"
            src={videoUrl}
            autoPlay
            loop
            muted
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              display: 'block',
              minHeight: { xs: '70vh', sm: 'auto' }, // Larger video on xs screens
            }}
          />
        )}
      </Box>

      {/* Log the final rendered component's styles */}
      {console.log('BasicSlideTemplate styles updated for larger image on xs screens')}
    </Box>
  );
};

export default BasicSlideTemplate;
