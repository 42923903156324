import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactDialog from './ContactDialog';
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteContact } from 'src/features/account/contacts.slice';
import { selectAllFilteredContacts, fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';

interface ContactsListProps {
  onSelectionChange: (selectedIds: string[]) => void;
}

const ContactsList: React.FC<ContactsListProps> = ({ onSelectionChange }) => {
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectAllFilteredContacts);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]); // Single selection state

  // Handler for 'Select All' checkbox change event
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let newSelectedIds: string[] = [];
    if (isChecked) {
      // Select all contacts
      newSelectedIds = contacts.map(contact => contact.id.toString());
      console.log('Selected all contacts:', newSelectedIds);
    } else {
      // Deselect all contacts
      newSelectedIds = [];
      console.log('Deselected all contacts');
    }
    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  const handleEditClick = (contactId: string) => {
    setSelectedContactId(contactId);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (contactId: string) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      dispatch(deleteContact(contactId)).then(() => {
        dispatch(fetchFilteredContacts({ query: '' }));
      });
    }
  };

  const handleDeleteSelected = () => {
    if (window.confirm('Are you sure you want to delete the selected contacts?')) {
      Promise.all(selectedIds.map((contactId) => dispatch(deleteContact(contactId))))
        .then(() => {
          setSelectedIds([]);
          onSelectionChange([]); // Reset selection
          dispatch(fetchFilteredContacts({ query: '' }));
        });
    }
  };

  const handleToggle = (contactId: string) => {
    const id = contactId.toString();
    const currentIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (currentIndex === -1) {
      newSelectedIds = [...selectedIds, id];
    } else {
      newSelectedIds = selectedIds.filter((sid) => sid !== id);
    }

    console.log('Toggled Contact ID:', id); // Log toggled ID
    console.log('New Selected IDs:', newSelectedIds); // Log new selected IDs

    setSelectedIds(newSelectedIds);
    onSelectionChange(newSelectedIds); // Pass selection up
  };

  return (
    <Box>
      {/* 'Select All' checkbox at the top */}
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedIds.length === contacts.length && contacts.length > 0}
            onChange={handleSelectAllChange}
            indeterminate={selectedIds.length > 0 && selectedIds.length < contacts.length}
          />
        }
        label="Select All"
      />

      <List>
        {contacts.map((contact) => (
          <ListItem
            key={contact.id}
            button
            onClick={() => handleToggle(contact.id.toString())}
          >
            <Checkbox
              checked={selectedIds.indexOf(contact.id.toString()) !== -1}
              tabIndex={-1}
              onChange={() => handleToggle(contact.id.toString())}
            />
            <ListItemText
              primary={contact.name || `${contact.first_name || ''} ${contact.last_name || ''}`.trim() || 'Unknown'}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    {contact.email || ''}
                  </Typography>
                  {' — '} {contact.phone_number || ''}
                </>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(contact.id.toString())}>
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteClick(contact.id.toString())}
                disabled={selectedIds.length > 0}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {selectedContactId && (
        <ContactDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          contactId={selectedContactId}
        />
      )}
    </Box>
  );
};

export default ContactsList;
